<template>
  <div class="home">
    <div class="header">
      <div class="logo">
        <img src="../assets/logo.png" alt="">
      </div>
      <div class="header-text">Sona Live</div>
    </div>
    <div class="banner">
      <div class="banner-content">
        <div class="title">Sona Live</div>
        <div class="slogan">Live Stream & Chat, Live it up</div>
      </div>
      <div class="screenshot" v-if="imgLoaded">
        <img src="../assets/screenshot.png" alt="">
      </div>
      <div class="rings">
        <img src="../assets/rings.png" alt="">
      </div>
    </div>
    <div class="footer">
      <div class="links">
        <router-link to="useragreement">User Agreement</router-link>
        <span class="divider">|</span>
        <router-link to="privacypolicy">Privacy Policy</router-link>
      </div>
      <div class="copyright">
        <p>Contact Us: <a href="mailto:sonaliveCS@google.com">sonaliveCS@google.com</a></p>
        <p>Copyright @ 2024 @ All Right Reserved GLOBAL PEAK INTERNATIONAL LIMITED</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      imgLoaded: false
    }
  },
  created() {
    let webpImg = new Image();
    webpImg.src = require('../assets/screenshot.png')
    webpImg.onload = () => {
      this.imgLoaded = true
      webpImg = null
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  position: relative;
}

.header {
  position: absolute;
  top: 17px;
  left: 67px;
  display: flex;
  align-items: center;
  z-index: 9;

  .logo {
    margin-right: 12px;

    & > img {
      height: 32px;
      width: 32px;
    }
  }

  &-text {
    color: #FFF;
    font-family: OPlus Sans SC 3.5;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
  }
}

$banner-height: calc(100vw * 0.4);
.banner {
  background: linear-gradient(135deg, #7E27EF 0%, #7E27EF 108.26%);;
  height: $banner-height;
  position: relative;

  &-content {
    position: absolute;
    top: 45%;
    left: 11%;

    .title {
      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: OPlus Sans SC 3.5;
      font-size: 66px;
      font-weight: 700;
      line-height: 20px;
    }

    .slogan {
      margin-top: 36px;
      color: #FFF;
      font-family: OPlus Sans SC 3.5;
      font-size: 40px;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: 1px;
    }
  }

  .screenshot {
    //border-radius: calc(($banner-height + 20px + 30px) * 0.07);
    //background: rgba(255, 255, 255, 0.90);
    //box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.16);
    //padding: 14.66px 15px 12.66px 15px;

    position: absolute;
    top: calc($banner-height * 0.18);
    right: calc($banner-height * 0.22);
    z-index: 10;

    & > img {
      height: calc($banner-height + 20px);
    }
  }
}

.footer {
  overflow: hidden;
  height: 323px;
  box-sizing: border-box;

  .links {
    margin-top: 90px;
    margin-left: 11%;
    color: #B236F8;

    a {
      color: #B236F8;
      font-family: OPlusSans 3.0;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-decoration: none;
    }

    .divider {
      margin: 0 20px;
    }
  }

  .copyright {
    margin-top: 106px;
    text-align: center;
    color: rgba(0, 0, 0, 0.40);
    font-family: OPlusSans 3.0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0 20px;

    a {
      color: #B236F8;
      font-family: OPlusSans 3.0;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.rings {
  height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;

  & > img {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    height: calc($banner-height * 1.6);
  }
}

@media screen and (max-width: 887px) {
  .banner-content .slogan {
    max-width: 300px;
  }
}

@media screen and (max-width: 1040px) {
  .banner-content {
    left: 6%;
  }
  .banner .screenshot {
    top: calc($banner-height * 0.1);
    right: calc($banner-height * 0.1);
  }
  .footer .links {
    margin-left: 6%;
  }
}

@media screen and (orientation: portrait) {
  .header {
    left: 20px;
  }
  .banner {
    height: 80vh;
  }
  .banner {
    &-content {
      .title {
        font-size: 50px;
      }
    }

    .screenshot {
      top: 60px;
      padding: 7px 8px 5px 8px;
    }
  }

  .footer {
    height: auto;

    .links {
      margin-top: 20px;
    }

    .copyright {
      margin-top: 40px;

      p:nth-child(2) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

}
</style>