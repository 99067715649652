import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/useragreement',
        name: 'user-agreement',
        component: () => import(/* webpackChunkName: "user-agreement" */ '../views/UserAgreementView.vue')
    },
    {
        path: '/privacypolicy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicyView.vue')
    },
    {
        path: '*',
        redirect: '/home'
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
})

export default router
